import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import classNames from 'classnames'

// Styling
import './avatar.scss'

// Types
import { ContentfulMedia } from 'types/contentful'

interface Props {
  name: string
  title: string
  image: ContentfulMedia
  email?: string
  displayEmail?: boolean
  alignment?: 'horizontal' | 'vertical'
}

export const Avatar: React.FC<Props> = ({
  name,
  title,
  image,
  email,
  displayEmail,
  alignment,
}) => {
  const classes = classNames('avatar', {
    [`avatar--${alignment}`]: alignment,
  })

  return (
    <div className={classes}>
      {image.gatsbyImageData && (
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={`Bild på ${name}`}
          className={'avatar__image'}
        />
      )}
      <div className="avatar__info">
        {title && <span className="avatar__title">{title}</span>}
        {name && <h3 className="avatar__name">{name}</h3>}
        {email && displayEmail && (
          <span className="avatar__email">{email}</span>
        )}
      </div>
    </div>
  )
}

Avatar.defaultProps = {
  displayEmail: false,
  alignment: 'vertical',
}
