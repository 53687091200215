import useSWR from 'swr'
import { getRelatedArticles } from 'services/contentful'

const fetcher = (_: string, categories: string[], slug: string) =>
  getRelatedArticles(slug, categories)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useRelatedArticles(slug: string, categories: string[]) {
  const key = [`/post/related/${slug}`, categories, slug]
  const { data, error } = useSWR(key, fetcher)

  return {
    articles: data?.items,
    isLoading: !error && !data,
    isError: error,
  }
}

export default useRelatedArticles
