import { get, merge } from 'lodash/fp'

import { ContentfulBlogPost } from 'types/contentful'

export const createStructuredArticle = (
  article: ContentfulBlogPost,
  fullUrl: string
) => {
  const data = {
    '@context': 'http://schema.org/',
    '@type': 'Article',
    headline: article.title,
    description: get('excerpt.excerpt', article),
    url: fullUrl || `/${article.slug}`,
    dateModified: article.updatedAt,
    datePublished: new Date(article.publishedAt).toISOString(),
    publisher: {
      '@type': 'Organization',
      name: 'Camelonta',
      url: 'http://camelonta.se',
      logo: 'https://camelonta.se/icons/icon-512x512.png',
    },
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': 'http://camelonta.se',
    },
  }

  const withAuthor = article.author
    ? merge(
        {
          author: get('author.fullName', article),
        },
        data
      )
    : data

  const withFeaturedImage =
    article.featuredMedia && article.featuredMedia.fluid
      ? merge(
          {
            image: get('featuredMedia.fluid.src', article),
          },
          withAuthor
        )
      : withAuthor

  return JSON.stringify(withFeaturedImage)
}
