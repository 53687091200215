import React, { FC } from 'react'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'

// Components
import Layout from 'components/layout'
import SEO from 'components/seo'
import { createMarkupFromDocument } from 'components/RichText'
import { Avatar } from 'components/Avatar'

// Hooks
import useRelatedArticles from 'hooks/useRelatedArticles'

// Styling
import './blog-post.scss'

// Utils
import { createStructuredArticle } from 'utils/structuredData'

// Types
import { ContentfulBlogPost } from 'types/contentful'
import { BlogPost } from 'components/BlogPosts'

type Props = {
  data: {
    post: ContentfulBlogPost
  }
  pageContext: {
    slug: string
    url: string
  }
}
const BlogPostTemplate: FC<Props> = (props) => {
  const { post } = props.data

  const { articles } = useRelatedArticles(post.slug, post.categories)

  const text = createMarkupFromDocument(post.content)

  const classes = classNames('blog-post', {
    'blog-post--has-featured-media': post.featuredMedia?.gatsbyImageData,
  })

  const structuredJSON = createStructuredArticle(
    post,
    `https://camelonta.se/${props.pageContext.url}`
  )

  return (
    <Layout theme="Dark">
      <SEO title={post.title}>
        <script type="application/ld+json">{structuredJSON}</script>
      </SEO>
      <article className={classes}>
        {post.featuredMedia?.gatsbyImageData && (
          <div className={'blog-post__media'}>
            <GatsbyImage
              image={post.featuredMedia.gatsbyImageData}
              alt={post.title}
              objectFit="cover"
              objectPosition="center"
            />
          </div>
        )}
        <div className={'blog-post__content standard-article'}>
          <h1 className="blog-post__title">{post.title}</h1>
          <div className="blog-post__text">{text}</div>
        </div>
        {post.author && (
          <div className={'blog-post__author'}>
            <Avatar
              name={post.author.fullName}
              title={post.author.workTitle}
              image={post.author.avatar}
              displayEmail={false}
              alignment={'horizontal'}
            />
          </div>
        )}
      </article>
      <div className="blog-post__related-articles">
        {articles?.map((article) => (
          <BlogPost
            key={article.sys.id}
            post={article.fields as any}
            mediaSrc={article.fields.featuredMedia.fields.file.url}
            mediaType="only-src"
            prefixUrl={'tips-och-trender'}
          />
        ))}
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const query = graphql`
  query SingleBlogPost($slug: String!) {
    post: contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      createdAt
      updatedAt
      publishedAt
      categories

      content {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            file {
              contentType
              url
            }
            fluid {
              base64
              srcWebp
              srcSetWebp
            }
          }
          ... on ContentfulBlogPost {
            __typename
            contentful_id
            slug
          }
          ... on ContentfulHtmlCode {
            __typename
            contentful_id
            name
            code {
              code
            }
          }
          ... on ContentfulPage {
            __typename
            contentful_id
            id
            slug
            parentPage {
              slug
              parentPage {
                slug
              }
            }
          }
        }
      }

      featuredMedia {
        gatsbyImageData(
          formats: [AUTO, WEBP]
          height: 640
          quality: 95
          placeholder: BLURRED
        )
      }

      author {
        fullName
        workTitle
        email
        avatar {
          fluid(quality: 90) {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }
  }
`
